import type { SearchResultsSectionData } from '@orus.eu/backend/src/services/search-service'
import { ensureError } from '@orus.eu/error'
import { useCrash } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { memo, useCallback, useEffect, useState } from 'react'
import { trpc } from '../../../../client'
import { useUpToDateStatus } from '../../../../lib/hooks/use-up-to-date-status'
import { useApi } from '../../../../lib/use-api/use-api'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { BackofficeSearchPageContent } from '../common/backoffice-search-page-content'

const PartnersSearchPage = memo(function PartnersSearchPage() {
  const searchIndexUpToDate = useUpToDateStatus(useCallback(() => trpc.search.isUpToDate.query(), []))
  switch (searchIndexUpToDate) {
    case 'checking':
      return <GlobalLoadingState />
    case 'up-to-date':
    case 'out-of-date':
      return <SearchBlockWrapper searchIndexUpToDate={searchIndexUpToDate} />
  }
})

export default PartnersSearchPage

const SearchBlockWrapper = memo<{ searchIndexUpToDate: 'up-to-date' | 'out-of-date' }>(function SearchBlock({
  searchIndexUpToDate,
}) {
  const crash = useCrash()
  const { organization } = useParams({ from: '/partner/$organization/search' })
  const [query, setQuery] = useState('')

  /**
   * The query for which more results were requested, if any
   */
  const [requestMoreQuery, setRequestMoreQuery] = useState<string | undefined>()
  const exactMatches = useApi(fetchExactMatches, query, organization)
  const [partialMatches, setPartialMatches] = useState<SearchResultsSectionData | undefined>()

  const partialMatchesRequested = requestMoreQuery && requestMoreQuery === query

  useEffect(() => {
    let cancelled = false
    if (!requestMoreQuery || requestMoreQuery !== query) {
      setPartialMatches(undefined)
      return
    }

    trpc.search.partnerSearchPartialMatches.query({ query: requestMoreQuery, organization }).then(
      (matches) => {
        if (cancelled) return
        setPartialMatches(matches)
      },
      (err: unknown) => {
        if (cancelled) return
        crash(ensureError(err))
      },
    )

    return () => {
      cancelled = true
    }
  }, [requestMoreQuery, partialMatchesRequested, query, crash, organization])

  const handleRequestMore = useCallback((requestMoreQuery: string) => {
    setRequestMoreQuery(requestMoreQuery)
  }, [])

  return (
    <BackofficeSearchPageContent
      searchIndexUpToDate={searchIndexUpToDate}
      query={query}
      setQuery={setQuery}
      exactMatches={exactMatches}
      partialMatchesRequested={partialMatchesRequested}
      partialMatches={partialMatches}
      handleRequestMore={handleRequestMore}
    />
  )
})

async function fetchExactMatches(query: string, organization: string): Promise<SearchResultsSectionData | 'no-query'> {
  const trimmedQuery = query.trim()
  if (!trimmedQuery) {
    return 'no-query'
  }
  return trpc.search.partnerSearchExactMatches.query({ query: trimmedQuery, organization })
}
